<template>
  <div class="py-40 center" v-if="myInfo">
    <p>Please wait ...</p>
    <b-spinner type="grow"></b-spinner>
  </div>
  <div class="py-40 center" v-else>
    <p>
      Thank you for choosing MOVX. We will see you there!
    </p>
    <p>
      Your payment has been processed and you will receive an email shortly.
    </p>
    <p>
      You can change or access your information by logging in at <a href="https://movxmovingcompany.org/">movxmovingcompany.org</a>
    </p>
    <p>
      or contacting our support team directly at
    </p>
    <p>
      <a href="tel:+1 747-253-6334">(747) 253-6334</a>
    </p>
    <p>
      <a href="mailto:keanu@Movxmovingcompany.org">keanu@Movxmovingcompany.org</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ConfirmPayment',
  computed: {
    myInfo() {
      return this.$store.state.myInfo;
    }
  },
  mounted() {
    this.$toast.success('Booked successfully.');
    if (this.myInfo) {
      setTimeout(() => {
        this.$router.push('/orders');
      }, 3000);
    }
  }
};
</script>
